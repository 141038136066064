import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import StrapiHtml from '@templates/strapi-cms/content-types/HTML'

export const HTML = data => <StrapiHtml data={data} />

const TipQuery = () => (
  <StaticQuery
    query={graphql`
      query TipCopy {
        strapiHtml(Title: { eq: "HTML - Tip Copy - Tip Restraint Product Description " }) {
          ...StrapiHtmlFragment
        }
      }
    `}
    render={data => HTML(data.strapiHtml)}
  />
)
export default TipQuery
