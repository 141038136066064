import PropTypes from 'prop-types'

import { PricingModel, PricingModelPropType } from './pricing-model'

class RoomConfigurationItemModel {
  constructor(values = {}) {
    this.sku = values?.sku || ''
    this.is_featured = values?.is_featured || false
    this.title = values?.title || ''
    this.sell_individually = values?.sell_individually || false
    this.pricing = new PricingModel(values?.pricing || {})
    this.label = values?.label || ''
    this.route = values?.route || ''
  }
}

const RoomConfigurationItemModelPropType = PropTypes.shape({
  sku: PropTypes.string,
  is_featured: PropTypes.bool,
  title: PropTypes.string,
  sell_individually: PropTypes.bool,
  pricing: PricingModelPropType,
  label: PropTypes.string,
  route: PropTypes.string,
})

export { RoomConfigurationItemModel, RoomConfigurationItemModelPropType }
