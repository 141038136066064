export const createCrumb = (crumb, slugPath, isHTML = false) => ({
  altDesc: `View All ${crumb} Products`,
  id: slugPath,
  slug: slugPath,
  text: crumb,
  isHTML,
})

export const generatePDPCrumbs = (breadcrumb, title) => {
  const crumbs = []
  if (breadcrumb?.breadcrumb_label && breadcrumb?.breadcrumb_url) {
    crumbs.push(createCrumb(breadcrumb.breadcrumb_label, breadcrumb.breadcrumb_url))
  }
  if (title) {
    crumbs.push(createCrumb(title, typeof window !== 'undefined' ? window.location.pathname : '', true))
  }
  return {
    crumbs,
  }
}

export const scrollToBonusBuys = ref =>
  typeof window !== 'undefined' ? window.scrollTo(0, ref.current.offsetTop - 180) : null
