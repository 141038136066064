class ShippingCostCodeModel {
  constructor(values = {}) {
    this.FL = values?.FL || ''
    this.SE = values?.SE || ''
    this.TX = values?.TX || ''
    this.OOM = values?.OOM || ''
  }
}

export default ShippingCostCodeModel
