import { setLocation, setShippingAddress } from '@redux/modules/location'
import { getLocation } from '@services/location'
import { getURLParam } from '@helpers/general'
import { abbreviateState } from '@helpers/geo-location'
import { regionToZipCode } from '@templates/constants'
import { productUrl } from '@helpers/route'
import { setupAnalytics, analyticsProduct } from '@helpers/google-tag-manager'

export const checkPLALocation = async (dispatch, regionIdParam) => {
  const regionId = regionIdParam || getURLParam('region_id')
  if (regionId) {
    const { zipCode } = regionToZipCode[regionId]
    const { response } = await getLocation(zipCode)
    if (response) {
      dispatch(setLocation(response))
      dispatch(setShippingAddress(`${response.city}, ${abbreviateState(response.state)}`))
    }
  }
}

export const generateSeeInRoomObjects = see_in_room => {
  const image =
    see_in_room?.primary_image ||
    see_in_room?.grid_image ||
    see_in_room?.primary_image_item ||
    see_in_room?.grid_image_item

  return see_in_room && image
    ? {
        image,
        link: productUrl(see_in_room?.route, see_in_room?.sku),
        title: see_in_room?.title,
        product: see_in_room,
      }
    : {}
}

export const pageAnalytics = product => {
  if (window && product) {
    setupAnalytics({
      pageData: {
        type: 'product',
        title: product.title,
        path: productUrl(product.title, product.sku),
      },
    })
    window.dataLayer.push({
      event: 'ee_detail',
      ecommerce: { detail: { products: [analyticsProduct(product)] } },
    })
  }
}
