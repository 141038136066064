import React from 'react'
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react'
import { objectOf, any } from 'prop-types'
import { getStoreName, getStoreHours } from '@helpers/store-locator'
import StoreInfo from '../../store-locator/store-info'
import '../../../assets/css/components/product/product-lia/product-lia-map.sass'

class ProductLIAMap extends React.Component {
  state = {
    showingInfoWindow: false,
    activeMarker: {},
    selectedMarker: {},
  }

  onLocationClick = (props, marker) => {
    const { store } = this.props
    const fullMarker = {
      ...props,
      title: store.Address1,
      name: getStoreName(store),
      lat: store.Location.latLng.lat,
      lng: store.Location.latLng.lng,
      city: store.City,
      state: store.State,
      storeNumber: store.StoreNumber || store.storeNumber,
      StoreNumber: store.StoreNumber || store.storeNumber,
      zip: store.Zip,
      phone: store.PhoneNumber,
      hours: getStoreHours(store.StoreHours),
    }
    this.setState({
      selectedMarker: fullMarker,
      activeMarker: marker,
      showingInfoWindow: true,
    })
  }

  onMapClicked = () => {
    const { showingInfoWindow } = this.state
    if (showingInfoWindow) {
      this.setState({
        showingInfoWindow: false,
        activeMarker: null,
      })
    }
  }

  render() {
    const { store, google } = this.props
    const { activeMarker, selectedMarker, showingInfoWindow } = this.state
    return (
      <Map
        initialCenter={{ lat: store.Location.latLng.lat, lng: store.Location.latLng.lng }}
        center={{ lat: store.Location.latLng.lat, lng: store.Location.latLng.lng }}
        className="product-lia-map"
        google={google}
        zoom={11}
        onClick={this.onMapClicked}
      >
        <Marker
          position={{ lat: store.Location.latLng.lat, lng: store.Location.latLng.lng }}
          title={`${store.City}${store.StoreName ? ` - ${store.StoreName}` : ''} ${store.StoreType}`}
          icon={{
            url: `https://maps.google.com/mapfiles/markerA.png`,
          }}
          onClick={this.onLocationClick}
        />
        <InfoWindow marker={activeMarker} visible={showingInfoWindow}>
          <StoreInfo selectedMarker={selectedMarker} />
        </InfoWindow>
      </Map>
    )
  }
}

ProductLIAMap.propTypes = {
  store: objectOf(any),
  google: objectOf(any),
}

export default GoogleApiWrapper({
  apiKey: `${process.env.GATSBY_GOOGLE_BROWSER_API_KEY}`,
})(ProductLIAMap)
