import PackageSkuItemModel from '@models/api/products/package-sku-item-model'

class PackageSkusModel {
  constructor(values = {}) {
    if (values?.FL) {
      this.FL = (values?.FL || []).map(item => new PackageSkuItemModel(item))
    }
    if (values?.SE) {
      this.SE = (values?.SE || []).map(item => new PackageSkuItemModel(item))
    }
    if (values?.TX) {
      this.TX = (values?.TX || []).map(item => new PackageSkuItemModel(item))
    }
    if (values?.OOM) {
      this.OOM = (values?.OOM || []).map(item => new PackageSkuItemModel(item))
    }
  }
}

export default PackageSkusModel
