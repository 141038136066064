import React, { useState, useEffect, useCallback } from 'react'
import PropTypes from 'prop-types'
import { productPrice, productAvailability } from '@helpers/product'
import { currencyFormatUS } from '@helpers/string-helper'
import { getRegionZone } from '@helpers/geo-location'
import { fetchPromotions } from '@services/checkout'
import { productFinancing } from '@helpers/finance'
import { productUrl } from '@helpers/route'
import RTGLink from '../../shared/link'
import ProductAddToCartSection from './product-add-to-cart-section'
import ProductFinancing from './product-financing'

const ProductPromotion = props => {
  const { product, promo, promoDescription, qualifierQuantity, targetQuantity, promoType } = props
  const [promotion, setPromotion] = useState(product?.promotionData)
  const [finance, setFinance] = useState(null)

  const isBuyXAndYGetZ = useCallback(() => promoType === 'BUY X AND Y GET Z DISCOUNT', [promoType])
  const setupPromo = useCallback(() => {
    if (promo && targetQuantity && qualifierQuantity) {
      const requestItems = [
        {
          sku: product.sku,
          quantity: parseInt(qualifierQuantity),
        },
        {
          sku: promo.sku,
          quantity: isBuyXAndYGetZ() ? 2 : parseInt(targetQuantity),
        },
      ]

      fetchPromotions({
        requestItems,
        region: getRegionZone().region,
        zone: getRegionZone().zone,
      }).then(data => {
        setPromotion(data)
        setFinance(productFinancing(data.cartTotal))
      })
    }
  }, [isBuyXAndYGetZ, product.sku, promo, qualifierQuantity, targetQuantity])

  useEffect(() => {
    setupPromo()
  }, [setupPromo, product])

  const promoRoute = promo?.route ? promo.route : null

  const targetProduct = promotion
    ? promotion.lineItems.find(item => !item.promotionStatus) ||
      promotion.lineItems.find(item => item.promotionStatus === 'UTILIZED')
    : null

  // const targetProduct = promotion ? promotion.lineItems.find(item => !item.promotionStatus) : null
  const promoProduct = promotion ? promotion.lineItems.find(item => item.promotionStatus === 'APPLIED') : null
  const minSavingsToShowSaveText = 50
  const shouldShowStrikethroughPrice = product?.strikethrough?.[getRegionZone().region] || true

  return (
    <>
      {promotion && promotion.totalSavings > 0 && (
        <div className="promotion-entry grid-x card">
          <div className="cell small-12 medium-6 large-9 grid-x">
            {/* Qualifier Content */}
            <div
              className={`cell card promotion-product small-12 medium-12 grid-x ${
                isBuyXAndYGetZ() ? 'large-3' : 'large-5'
              }`}
            >
              {qualifierQuantity > 1 && <div className="promo-count">qty: {qualifierQuantity}</div>}
              <div className="cell promotion-image-container small-3">
                <img
                  src={`${product.grid_image ? product.grid_image : product.primary_image}&h=150`}
                  alt={product.title ? product.title : product.sku}
                />
              </div>
              <div className="cell promotion-title-container small-9 large-9 grid-x">
                <div className="cell small-12">
                  <span className="promo-title" dangerouslySetInnerHTML={{ __html: product.title }} />
                </div>
                <div className="cell small-12">
                  <p className="product-price">
                    <span>{currencyFormatUS(productPrice(product))}</span>
                  </p>
                </div>
              </div>
            </div>

            {/* Divider */}
            <span className="cell plus small-12 medium-1">+</span>

            {isBuyXAndYGetZ() && (
              <>
                <RTGLink
                  data={{
                    slug: productUrl(promoRoute),
                    altDesc: promo.title ? promo.title : '',
                  }}
                  className={`card pop-out promotion-product small-12 medium-12 grid-x ${
                    isBuyXAndYGetZ() ? 'large-3' : 'large-5'
                  }`}
                >
                  {targetQuantity > 1 && <div className="promo-count">qty: {targetQuantity}</div>}
                  <div className="promotion-image-container small-3">
                    <img src={`${promo.image}&h=150`} alt={promo.title ? promo.title : promo.sku} />
                  </div>
                  <div className="cell promotion-title-container small-9 grid-x">
                    <div className="cell small-12">
                      <span className="promo-title" dangerouslySetInnerHTML={{ __html: promo.title }} />
                    </div>
                    {promotion.lineItems && promotion.bonusBuyTotal >= 0 && (
                      <div className="cell small-12">
                        <p className="product-price">
                          <span className="">
                            {currencyFormatUS(
                              promotion.lineItems.length > 2
                                ? promotion.lineItems[1].unitPrice
                                : promotion.lineItems[0].unitPrice,
                            )}
                          </span>
                        </p>
                      </div>
                    )}
                  </div>
                </RTGLink>
                <span className="cell plus small-12 medium-1">+</span>
              </>
            )}

            {/* Target Content */}
            <RTGLink
              data={{
                slug: productUrl(promoRoute),
                altDesc: promo.title ? promo.title : '',
              }}
              className={`card pop-out promotion-product small-12 medium-12 grid-x ${
                isBuyXAndYGetZ() ? 'large-3' : 'large-5'
              }`}
            >
              {targetQuantity > 1 && <div className="promo-count">qty: {targetQuantity}</div>}
              <div className="promotion-image-container small-3">
                <img src={`${promo.image}&h=150`} alt={promo.title ? promo.title : promo.sku} />
              </div>

              <div className="cell promotion-title-container small-9 grid-x">
                <div className="cell small-12">
                  <span className="promo-title" dangerouslySetInnerHTML={{ __html: promo.title }} />
                </div>
                {promotion.lineItems && promotion.bonusBuyTotal >= 0 && (
                  <div className="cell small-12">
                    <p className="product-price">
                      {shouldShowStrikethroughPrice && (
                        <span className="strikethrough">{currencyFormatUS(targetProduct.unitPrice)}</span>
                      )}
                      <span className="strikethrough-sale">{currencyFormatUS(promoProduct.bonusPrice)}</span>
                    </p>
                  </div>
                )}
              </div>
            </RTGLink>
          </div>

          {/* Summary Content */}
          <div className="cell small-12 medium-5 large-3 grid-x center promo-savings-container">
            <div className="cell small-12">
              {promotion.totalSavings >= minSavingsToShowSaveText && (
                <p className="promo-total-savings">{`SAVE ${currencyFormatUS(promotion.totalSavings)}`}</p>
              )}
              <p className="product-price">
                <span className="strikethrough-sale">{currencyFormatUS(promotion.cartTotal)}</span>
              </p>
              {finance && finance.financeAmount > 0 && <ProductFinancing financeAmount={finance.financeAmount} />}
            </div>
            <div className="cell small-12">
              <ProductAddToCartSection
                availability={productAvailability(product)}
                product={product}
                price={productPrice(product)}
                hideShipping
                promoDescription={promoDescription}
                promoItems={[promo]}
                promoQualifierQuantity={qualifierQuantity}
                promoTargetQuantity={isBuyXAndYGetZ() ? 2 : targetQuantity}
                promoTargetPrice={promoProduct.bonusPrice}
                promoStrikethroughPrice={targetProduct.unitPrice}
                buttonText="add offer to cart"
                stockMessage="bonus buy"
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

ProductPromotion.propTypes = {
  promoType: PropTypes.string,
  product: PropTypes.object,
  promo: PropTypes.object,
  promoDescription: PropTypes.string,
  qualifierQuantity: PropTypes.string,
  targetQuantity: PropTypes.string,
}

export default ProductPromotion
