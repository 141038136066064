import React, { memo, useEffect, useState } from 'react'
import { arrayOf, string, any } from 'prop-types'
import { Box, makeStyles } from '@material-ui/core'
import ArrowRightIcon from '@material-ui/icons/ArrowRight'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'
import { productUrl } from '@helpers/route'
import { theme, Room, SystemProvider } from '@rtgdev/design-system'
import { Swiper, SwiperSlide } from 'swiper/react'
import RTGLink from '@shared/link'
import { fetchProductWarehouseAvailability } from '@services/product'
import { getCurrentLocation } from '@helpers/geo-location'

const blue = theme?.palette?.primary?.main || '#0053A0'

const useStyles = makeStyles({
  productUpgrades: {
    '& .swiper-container': {
      height: '412px',
      [theme.breakpoints.up('md')]: {
        height: 'auto',
      },
    },
    '& .swiper-slide': {
      width: '389px',
    },
    '& .swiper-button-disabled:focus': {
      outline: 'none',
    },
    '& .swiper-button-disabled svg': {
      color: '#F7F7F7',
    },
    '& .swiper-slide-active': {
      '& .MuiPaper-outlined': {
        borderColor: '#fff',
        borderWidth: '1px',
        transition: 'border-color 0.5s ease-in-out',
      },
    },
    '& .swiper-scrollbar': {
      display: 'block',
      [theme.breakpoints.up('md')]: {
        display: 'none',
      },
    },
    '& .custom-pagination-container': {
      display: 'none',
      [theme.breakpoints.up('md')]: {
        display: 'flex',
      },
      '& .custom-pagination': {
        display: 'none',
        [theme.breakpoints.up('md')]: {
          display: 'flex',
        },
        justifyContent: 'center',
        '& .swiper-pagination-bullet': {
          width: '24px',
          height: '24px',
          textAlign: 'center',
          lineHeight: '23px',
          fontSize: '20px',
          color: 'white',
          border: `1px solid ${blue}`,
          background: 'none',
          margin: '0px 7px',
          opacity: '1',
          outline: 'none',
        },
        '& .swiper-pagination-bullet-active': {
          color: blue,
          background: blue,
        },
      },
    },
  },
})

// Create offset for last slide in room options swiper to properly display pagination
function useWindowSize() {
  // Initialize state with undefined width/height so server and client renders match
  const [windowSize, setWindowSize] = useState({
    width: undefined,
  })
  useEffect(() => {
    const swiper = document.querySelector('#room-options-swiper')

    // Handler to call on window resize
    function handleResize() {
      // Set window width/height to state
      setWindowSize({
        width: swiper.offsetWidth,
      })
    }

    // Add event listener
    window.addEventListener('resize', handleResize)
    // Call handler right away so state gets updated with initial window size
    handleResize()
    // Remove event listener on cleanup
    return () => window.removeEventListener('resize', handleResize)
  }, []) // Empty array ensures that effect is only run on mount
  return windowSize
}

const RoomOptionTile = (upgrade, index) => {
  const { sku, route, analyticsProduct, title, displayNamePDP, price, room_savings, items, availability_date } =
    upgrade || {}
  if (items.length > 0) {
    items.map(item => {
      if (item?.image) {
        return {
          ...item,
          image: `${item.image}&h=52`,
        }
      }
      return item
    })
  }
  const location = getCurrentLocation()
  const [availability, setAvailability] = useState(availability_date || '')
  // TODO: Resolve console warning for this effect
  // TODO: refactor to react-query
  useEffect(() => {
    fetchProductWarehouseAvailability(sku, location.distribution_index, location.state)
      .then(res => res.data.earliestAvailability)
      .then(date => setAvailability(date))
  }, [sku, location.distribution_index, location.state])
  return (
    <RTGLink
      className="cell"
      data={{
        slug: productUrl(route),
        title: sku,
        category: 'product-upgrade',
        action: 'upgrade click',
        label: sku,
      }}
      noTabIndex
      trackingData={{
        event: 'ee_click',
        ecommerce: {
          click: {
            list: `upgrade_other_room_options`,
            position: index + 1,
            products: [analyticsProduct],
          },
        },
      }}
    >
      <Room
        title={displayNamePDP || title}
        price={price}
        room_savings={room_savings}
        availability_date={availability}
        items={items}
      />
    </RTGLink>
  )
}

const RoomOptionTileMemo = memo(RoomOptionTile)

const ProductUpgradesSwiper = props => {
  const classes = useStyles()
  const { upgrades = [], heading = '' } = props
  const swiperContainer = useWindowSize()
  const swiperWidth = swiperContainer.width

  return (
    <SystemProvider>
      <Box id="room-options-swiper" className={classes.productUpgrades}>
        <Swiper
          slidesPerGroup={1}
          slidesPerView="auto"
          grabCursor
          centeredSlides={false}
          navigation={{
            nextEl: '.next-slide',
            prevEl: '.prev-slide',
          }}
          pagination={{
            el: '.custom-pagination',
            clickable: true,
            renderBullet: (index, className) => `<span class='${className}'></span>`,
          }}
          spaceBetween={20}
          // Dynamically set offset so that last page shows only 1 card
          slidesOffsetAfter={swiperWidth - 389}
          scrollbar={{
            draggable: true,
            hide: false,
            snapOnRelease: true,
          }}
        >
          {upgrades &&
            upgrades.map((upgrade, index) => (
              <SwiperSlide
                key={`upgrade_other_room_options_${upgrade?.sku}`}
                data-hash={`upgrade_other_room_options_${upgrade?.sku}`}
              >
                <RoomOptionTile {...upgrade} />
              </SwiperSlide>
            ))}
        </Swiper>
        {upgrades.length > 2 && (
          <Box
            className="custom-pagination-container"
            display="flex"
            alignItems="center"
            justifyContent="center"
            mt="10px"
          >
            <Box className="prev-slide" type="button" display="flex" justifyContent="center" alignContent="center">
              <ArrowLeftIcon htmlColor="#0053a0" fontSize="large" />
            </Box>
            <div className="custom-pagination" />
            <Box className="next-slide" type="button" display="flex" justifyContent="center" alignContent="center">
              <ArrowRightIcon htmlColor="#0053a0" fontSize="large" />
            </Box>
          </Box>
        )}
      </Box>
    </SystemProvider>
  )
}

ProductUpgradesSwiper.propTypes = {
  upgrades: arrayOf(any),
  heading: string,
}

export default memo(ProductUpgradesSwiper)
