import { PricingModel } from '@models/api/products/pricing-model'

class ItemsInRoomItemModel {
  constructor(values = {}) {
    this.sku = values?.sku || ''
    this.quantity = values?.quantity || 0
    this.dimensions = values?.dimensions || ''
    this.image = values?.image || ''
    this.generic_name = values?.generic_name || ''
    this.title = values?.title || ''
    this.route = values?.route || ''
    this.pricing = new PricingModel(values?.pricing || {})
  }
}

export default ItemsInRoomItemModel
