import PackageSkuItemV2Model from '@models/api/products/package-sku-item-v2-model'

class PackageSkusV2Model {
  constructor(values = {}) {
    if (values?.FL_0) {
      this.FL_0 = (values?.FL_0 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.FL_1) {
      this.FL_1 = (values?.FL_1 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.FL_2) {
      this.FL_2 = (values?.FL_2 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.FL_3) {
      this.FL_3 = (values?.FL_3 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.FL_4) {
      this.FL_4 = (values?.FL_4 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.FL_5) {
      this.FL_5 = (values?.FL_5 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.FL_6) {
      this.FL_6 = (values?.FL_6 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }

    if (values?.SE_0) {
      this.SE_0 = (values?.SE_0 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.SE_1) {
      this.SE_1 = (values?.SE_1 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.SE_2) {
      this.SE_2 = (values?.SE_2 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.SE_3) {
      this.SE_3 = (values?.SE_3 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.SE_4) {
      this.SE_4 = (values?.SE_4 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.SE_5) {
      this.SE_5 = (values?.SE_5 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.SE_6) {
      this.SE_6 = (values?.SE_6 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }

    if (values?.TX_0) {
      this.TX_0 = (values?.TX_0 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.TX_1) {
      this.TX_1 = (values?.TX_1 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.TX_2) {
      this.TX_2 = (values?.TX_2 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.TX_3) {
      this.TX_3 = (values?.TX_3 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.TX_4) {
      this.TX_4 = (values?.TX_4 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.TX_5) {
      this.TX_5 = (values?.TX_5 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.TX_6) {
      this.TX_6 = (values?.TX_6 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }

    if (values?.OOM_0) {
      this.OOM_0 = (values?.OOM_0 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.OOM_1) {
      this.OOM_1 = (values?.OOM_1 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.OOM_2) {
      this.OOM_2 = (values?.OOM_2 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.OOM_3) {
      this.OOM_3 = (values?.OOM_3 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.OOM_4) {
      this.OOM_4 = (values?.OOM_4 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.OOM_5) {
      this.OOM_5 = (values?.OOM_5 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
    if (values?.OOM_6) {
      this.OOM_6 = (values?.OOM_6 || []).map(item => new PackageSkuItemV2Model(item || {}))
    }
  }
}

export default PackageSkusV2Model
