import React from 'react'
import { StaticQuery, graphql } from 'gatsby'
import StrapiHtml from '@templates/strapi-cms/content-types/HTML'

export const HTML = data => <StrapiHtml data={data} />

const TeakQuery = () => (
  <StaticQuery
    query={graphql`
      query TeakFurnitureCare {
        strapiHtml(Title: { eq: "HTML - Teak Furniture Care Message" }) {
          ...StrapiHtmlFragment
        }
      }
    `}
    render={data => HTML(data.strapiHtml)}
  />
)
export default TeakQuery
