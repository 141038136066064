import ItemsInRoomItemModel from '@models/api/products/items-in-room-item-model'

class ItemsInRoomModel {
  constructor(values = {}) {
    if (values?.FL) {
      this.FL = (values?.FL || []).map(item => new ItemsInRoomItemModel(item || {}))
    }
    if (values?.SE) {
      this.SE = (values?.SE || []).map(item => new ItemsInRoomItemModel(item || {}))
    }
    if (values?.TX) {
      this.TX = (values?.TX || []).map(item => new ItemsInRoomItemModel(item || {}))
    }
    if (values?.OOM) {
      this.OOM = (values?.OOM || []).map(item => new ItemsInRoomItemModel(item || {}))
    }
  }
}

export default ItemsInRoomModel
