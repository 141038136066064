class IsFeaturedModel {
  constructor(values = {}) {
    this.FL = values?.FL || false
    this.SE = values?.SE || false
    this.TX = values?.TX || false
    this.OOM = values?.OOM || false
  }
}

export default IsFeaturedModel
