import PropTypes from 'prop-types'

import { RoomConfigurationItemModel, RoomConfigurationItemModelPropType } from './room-configuration-item-model'

class RoomConfigurationsModel {
  constructor(values = {}) {
    if (values?.FL) {
      this.FL = (values?.FL || []).map(item => new RoomConfigurationItemModel(item))
    }
    if (values?.SE) {
      this.SE = (values?.SE || []).map(item => new RoomConfigurationItemModel(item))
    }
    if (values?.TX) {
      this.TX = (values?.TX || []).map(item => new RoomConfigurationItemModel(item))
    }
    if (values?.OOM) {
      this.OOM = (values?.OOM || []).map(item => new RoomConfigurationItemModel(item))
    }
  }
}

const RoomConfigurationsModelPropType = PropTypes.shape({
  FL: PropTypes.arrayOf(RoomConfigurationItemModelPropType),
  SE: PropTypes.arrayOf(RoomConfigurationItemModelPropType),
  TX: PropTypes.arrayOf(RoomConfigurationItemModelPropType),
  OOM: PropTypes.arrayOf(RoomConfigurationItemModelPropType),
})

export { RoomConfigurationsModel, RoomConfigurationsModelPropType }
