import React, { useState, useEffect } from 'react'
import { getAlgoliaRecommendation } from '@services/checkout'
import { productAvailability, sortByPriceDescending } from '@helpers/product'
import { string, bool, objectOf, array } from 'prop-types'
import { getRegionZone } from '@helpers/geo-location'
import SimpleSlider from '@shared/slider'
import ProductTile from '../product/product-tile'

function SimilarItems({ sku, heading = 'products', similarItems = [] }) {
  const { region } = getRegionZone()
  const [arrayItems, setArrayItems] = useState(similarItems)

  useEffect(() => {
    getAlgoliaRecommendation({
      sku,
      region,
    })
      .then(data => {
        const availableProduct = data.filter(p => productAvailability(p)) || null
        setArrayItems(sortByPriceDescending(availableProduct))
      })
      .catch(() => setArrayItems([]))
  }, [sku, region])
  return (
    <>
      {arrayItems && arrayItems.length > 0 && (
        <div className="product-collection-slider grid-x grid-y grid-margin-x grid-margin-y grid-padding-y">
          <div className="cell small-12 grid-x grid-margin-x">
            <SimpleSlider
              data={{
                heading: `Similar ${heading}`,
              }}
              noExtraMargin
            >
              {arrayItems.map((item, index) => (
                <ProductTile
                  sku={item.sku}
                  key={`similar_products_to_${item.sku}`}
                  viewType="grid"
                  index={index}
                  showIncludes={false}
                  source="similar-products"
                  route={item.route}
                />
              ))}
            </SimpleSlider>
          </div>
        </div>
      )}
    </>
  )
}

SimilarItems.propTypes = {
  sku: string,
  heading: string,
  similarItems: objectOf(array),
}

export default SimilarItems
