class WarehouseAvailabilityItemModel {
  constructor(values = {}) {
    this.inStock = values?.inStock || ''
    this.isAvailable = values?.isAvailable || false
    this.availabilityDate = values?.availabilityDate || ''
    this.availableDateTimestamp = values?.availableDateTimestamp || 0
  }
}

export default WarehouseAvailabilityItemModel
