// dependencies
import React, { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import PropTypes from 'prop-types'
import { getRegion } from '@helpers/geo-location'
import { makeStyles } from '@material-ui/core'
// components
import StrapiBanner from '@templates/strapi-cms/content-types/Banner'

const PromoBannersQuery = graphql`
  query PromoBannersQuery {
    allStrapiPdppromoBanner {
      edges {
        node {
          __typename
          SKUs
          Default {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          SE {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          FL {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          TX {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
          OOM {
            Banner {
              ...StrapiPageBannerFragment
            }
          }
        }
      }
    }
  }
`

/**
 * Filters and Maps PDP banners based on a given SKU
 * @param {string} productSku
 * @param {'SE' | 'FL' |'TX' | 'OOM'} region
 * @param {object} data
 * @returns {object[]}
 */
const getPromoBanners = (productSku, region, data) => {
  if (!data || !productSku) return []

  const edges = data?.allStrapiPdppromoBanner?.edges || []

  return edges
    .filter(({ node: { SKUs } }) => Array.isArray(SKUs) && SKUs.some(sku => sku === productSku))
    .map(({ node }) => {
      const regionBasedContent = region && node[region]
      return regionBasedContent?.Banner || node.Default?.Banner || null
    })
    .filter(Boolean)
}

const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',
    display: 'block',
    height: 'auto',
    margin: 0,
    paddingBottom: `${theme.spacing(1)}px`,
  },
}))

const PDPPromoBanner = ({ sku }) => {
  const classes = useStyles()
  const data = useStaticQuery(PromoBannersQuery)
  const banners = useMemo(() => getPromoBanners(sku, getRegion(), data), [sku, data])

  return (
    <div className={classes.container}>
      {banners.map((Banner, index) => (
        <StrapiBanner data={{ Banner }} contentIndex={index} key={Banner.id} />
      ))}
    </div>
  )
}

PDPPromoBanner.propTypes = {
  sku: PropTypes.string,
}

export default PDPPromoBanner
