import React from 'react'
import PropTypes from 'prop-types'
import { productPrice } from '@helpers/product'
import { weekdays, getStandardTime } from '@helpers/string-helper'
import { expandState } from '@helpers/geo-location'
import { getStoreName, getStoreSlug } from '@helpers/store-locator'
import ProductJsonLd from '../product-json-ld'
import ProductLIAMap from './product-lia-map'
import RTGLink from '../../shared/link'
import SeeInStores from '../product-parts/product-see-in-stores'
import '../../../assets/css/components/product/product-lia/product-lia.sass'

export default class ProductLIA extends React.Component {
  getOpen = (store, tomorrow = false) => {
    const today = new Date().getDay()
    const currentDay = tomorrow && today + 1 === 7 ? weekdays[0] : weekdays[today + (tomorrow ? 1 : 0)]
    if (currentDay) {
      const storeOpen = store.StoreHours[`${currentDay.toLowerCase()}Open`]
      const storeClose = store.StoreHours[`${currentDay.toLowerCase()}Closed`]
      if (storeOpen && storeClose) {
        return `${storeOpen ? `Open from ${getStandardTime(storeOpen)} to ${getStandardTime(storeClose)}` : 'Closed'}`
      }
    }
    return ''
  }

  getHours = (store, day) => {
    const storeOpen = store.StoreHours[`${day.toLowerCase()}Open`]
    const storeClose = store.StoreHours[`${day.toLowerCase()}Closed`]
    if (storeOpen && storeClose) {
      return `${getStandardTime(storeOpen)} - ${getStandardTime(storeClose)}`
    }
    return ''
  }

  getStorePath = store => (store.StoreName ? `-${store.StoreName.toLowerCase().replace(' ', '-')}` : '')

  render() {
    const { product, store, region, zone, available } = this.props
    let storeName
    if (store) {
      storeName = getStoreName(store)
    }
    if (store) {
      return (
        <>
          <div className="product-lia grid-x">
            <div className="product-img small-12 large-6">
              {product && (
                <span className="product-image">
                  {(product.primary_image || product.grid_image) && (
                    <img
                      src={`${product.primary_image || product.grid_image}&h=385`}
                      className="small-image"
                      alt={product.title}
                    />
                  )}
                </span>
              )}
            </div>
            {product.description && (
              <div className="product-desc small-12 large-6">
                <h1 className="product-title" dangerouslySetInnerHTML={{ __html: product.title }} />
                <span className="price">${productPrice(product, null, region, zone)}</span>
                <RTGLink
                  data={{
                    slug: `${window && window.location.pathname}`,
                    category: 'product-lia',
                    action: 'click',
                    label: 'buy now',
                  }}
                  className="blue-action-btn"
                >
                  Buy Now
                </RTGLink>
                <h2>product description</h2>
                <div>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: product.description,
                    }}
                  />
                </div>
                <div className="small-12">
                  {product.dimensions && <div className=" product-dim-sku">{`Dimensions: ${product.dimensions}`}</div>}
                </div>
                <div className="small-12 product-dim-sku">SKU: {product.sku && product.sku.toUpperCase()}</div>
              </div>
            )}
            {available && (
              <div className="map">
                <ProductLIAMap store={store} />
              </div>
            )}
            <div className="store-info-lia">
              {available ? (
                <>
                  <span className="on-display">On display to order at:</span>
                  <RTGLink
                    data={{
                      url: getStoreSlug(store),
                      text: `${store.City} -${store.StoreName ? ` ${store.StoreName}` : ''} ${store.StoreType}`,
                      category: 'store',
                      action: 'click',
                      label: `${store.City} -${store.StoreName ? ` ${store.StoreName}` : ''} ${store.StoreType}`,
                    }}
                    className="store-link"
                  />
                  <span className="info-field">{store.Address1}</span>
                  {store.Address2 && <span className="info-field">{store.Address2}</span>}
                  <span className="info-field">
                    {store.City}, {store.State}, {store.Zip}
                  </span>
                  <RTGLink
                    data={{
                      url: `tel:${store.PhoneNumber.replace('/', '-')}`,
                      title: `Call ${storeName} Store`,
                      category: 'store-locator',
                      action: 'call store click',
                      label: storeName,
                    }}
                    className="store-link"
                  >
                    {store.PhoneNumber.replace('/', '-')}
                  </RTGLink>
                  <span className="today">
                    <span className="bold">Today: </span>
                    {this.getOpen(store)}
                  </span>
                  <span className="tomorrow">
                    <span className="bold">Tomorrow: </span>
                    {this.getOpen(store, true)}
                  </span>
                </>
              ) : (
                <>
                  <span className="info-field">Not available for display or order at:</span>
                  <RTGLink
                    data={{
                      url: `/stores/${expandState(store.State)
                        .toLowerCase()
                        .replace(' ', '-')}/${store.City.toLowerCase().replace(' ', '-')}${
                        store.StoreName ? ` ${store.StoreName.toLowerCase().replace(' ', '-')}` : ''
                      }-${store.StoreType.toLowerCase().replace(' ', '-')}-${store.StoreNumber}`,
                      text: `${store.City} -${store.StoreName ? ` ${store.StoreName}` : ''} ${store.StoreType}`,
                      category: 'store',
                      action: 'click',
                      label: `${store.City} -${store.StoreName ? ` ${store.StoreName}` : ''} ${store.StoreType}`,
                    }}
                    className="store-link"
                  />
                </>
              )}
              <SeeInStores
                sku={product.sku}
                title={product.title}
                primary_image={product.primary_image}
                zip={store.Zip}
                customButtonText="Change Location >"
                lia
              />
            </div>
          </div>
          <ProductJsonLd product={product} />
        </>
      )
    }
    return null
  }
}

ProductLIA.propTypes = {
  available: PropTypes.bool,
  product: PropTypes.object,
  region: PropTypes.string,
  store: PropTypes.object,
  zone: PropTypes.object,
}
