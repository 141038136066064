import { CatalogAvailabilityModel } from '@models/api/products/catalog-availability-model'

class ColorModel {
  constructor(values = {}) {
    this.sku = values?.sku || ''
    this.variation_value = values?.variation_value || ''
    this.title = values?.title || ''
    this.catalog_availability = new CatalogAvailabilityModel(values?.catalog_availability || {})
  }
}

export default ColorModel
