import React, { useState, useRef } from 'react'
import PropTypes from 'prop-types'
import Grid from '@material-ui/core/Grid'
import Paper from '@material-ui/core/Paper'
import Box from '@material-ui/core/Box'
import { analyticsProduct, addToDataLayer_nextgen } from '@helpers/google-tag-manager'
import Breadcrumb from '@templates/strapi-cms/content-types/Breadcrumbs'
import { makeStyles } from '@material-ui/core/styles'
import { getRequiredAddon, getProductImages } from '@helpers/product'
import useHasWindow from '@helpers/hooks/useWindow'
import { getRegionZone } from '@helpers/geo-location'
import SaleFlag from '@shared/sale-flag'
import PDPPromoBanner from '@templates/strapi-cms/content-types/PDPPromoBanner'
import ProductUpgradesV2 from '@components/product/product-parts/product-upgrades-v2'
import styled from 'styled-components'
import { theme, SlideTypeEnum, SmartSwiper } from '@rtgdev/design-system'
import { useUpdateFavorites, useIsFavorited } from '@hooks/auth'
import { FAVORITE_BUTTON } from '../../../@rtg2022'
import ProductDescription from '../product-parts/product-description'
import ProductInfo from '../product-info'
import ProductIncludesSlider from '../product-parts/product-includes-slider'
import ProductPromotions from '../product-parts/product-promotions'
import ShopTheRoom from '../views/ShopTheRoom'
import RequiredAddons from '../views/RequiredAddons'

import '../css/shop-the-room.sass'
import { scrollToBonusBuys } from './helpers'

const regionInPR = getRegionZone().region === 'PR'

const useStyles = makeStyles(() => ({
  leftColumn: {
    position: 'relative',
    maxHeight: 660,
    height: 'calc(100vw / 2.35)',
    [theme.breakpoints.down('lg')]: {
      height: 'calc(100vw / 2.35)',
    },
    [theme.breakpoints.down('md')]: {
      height: 'calc(100vw / 2.25)',
    },
    [theme.breakpoints.down('sm')]: {
      height: 'calc(100vw / 2)',
    },
    [theme.breakpoints.down('xs')]: {
      height: 'calc(100vw / 1.35)',
      paddingBottom: '0',
    },
  },
  rightColumn: {
    [theme.breakpoints.down('sm')]: {
      paddingTop: '0 !important',
      paddingBottom: '0 !important',
    },
  },
  favoriteButton: {
    position: 'absolute',
    top: 10,
    right: 10,
    zIndex: 10,
  },
}))

const PaperWrapper = styled(Paper)`
  padding: 0.938rem;
`

const BoxWrapper = styled(Box)`
  padding: 0 !important;
`

const getUpgradesWithAnalytics = ({ room_package_upgrades, sku }) => {
  const filteredUpgrades = room_package_upgrades?.filter(u => u.sku && u.title && u.pricing)
  const upgradesWithAnalytics = filteredUpgrades?.map((upgrade, index) => ({
    ...upgrade,
    analyticsProduct: analyticsProduct(upgrade, 1, index + 1),
  }))
  const selectedUpgrade = upgradesWithAnalytics.find(item => item?.sku === sku) || null
  const nonSelectedUpgrades = upgradesWithAnalytics.filter(item => item?.sku !== sku)
  return { upgradesWithAnalytics, selectedUpgrade, nonSelectedUpgrades }
}

const getProductMediaUrls = product =>
  getProductImages(product).map(url => {
    const image = {
      url: `${url}&h=1190&w=1190`,
      alt: product.title,
      // originalSrc would contain the original src/url of the image without controlling height and width
      originalSrc: url,
    }
    if (url?.includes?.('youtube'))
      return {
        type: SlideTypeEnum.youtube,
        thumbnail: `${product.video_thumbnail || url}`,
        ...image,
      }
    return {
      type: SlideTypeEnum.image,
      thumbnail: `${url}&h=52`,
      ...image,
    }
  })

/**
 * ProductDetail controller
 * -  Renders the PDP presentational component (refactored)
 * -  Documents business and implementation logic for all PDP components?
 * @param {*} props
 */
const ProductDetailView = ({
  product,
  items_in_room,
  promotions,
  availabilityDate,
  room_package_upgrades = [],
  see_in_room,
}) => {
  const isWindow = useHasWindow()
  const classes = useStyles()
  const { region } = getRegionZone()
  const addons = product?.addon_items || []
  const availableAddons = addons?.filter(addon => !!addon.catalog_availability[region])
  const requiredAddons = availableAddons?.filter(addon => addon.addon_required)
  const formattedAddon = getRequiredAddon(requiredAddons)
  const [userSelectedAddons, setUserSelectedAddons] = useState([])
  const [requiredAddonsSelected, setRequiredAddonsSelected] = useState([])
  const productMediaUrls = getProductMediaUrls(product)
  const { selectedUpgrade, nonSelectedUpgrades } = getUpgradesWithAnalytics({
    room_package_upgrades,
    sku: product.sku,
  })
  const promoRef = useRef(null)

  //  *************  Favorites Logic *****************************************************************
  const isFavorited = useIsFavorited(product.sku)
  const handleFavoriteClick = useUpdateFavorites(product)

  return (
    <>
      <Grid container direction="column" wrap="nowrap">
        <Grid container item direction="row" justify="space-between">
          <Grid item xs={12} sm={6} md={7} lg={8}>
            <Breadcrumb productBreadcrumbs={product.breadCrumbs} productTitle={product.title} isPDP />
          </Grid>
          <Grid container item xs={12} sm={6} md={5} lg={4} justify="flex-end">
            <ShopTheRoom product={product} single_item_room={product.single_item_room} see_in_room={see_in_room} />
          </Grid>
        </Grid>
        <PDPPromoBanner sku={product.sku} />
        <PaperWrapper elevation={0} square className="paper-wrapper">
          <BoxWrapper p={2} className="box-wrapper">
            <Grid container item spacing={3}>
              <Grid className={classes.leftColumn} item xs={12} sm={8} style={{ padding: '0' }}>
                {!regionInPR && (
                  <>
                    <div className={classes.favoriteButton}>
                      <FAVORITE_BUTTON checked={isFavorited} handleClick={() => handleFavoriteClick} />
                    </div>
                  </>
                )}
                <SaleFlag
                  product={product}
                  bonusBuyOverrideText="CLICK FOR BONUS BUY!"
                  availabilityDate={availabilityDate}
                  onClick={() => scrollToBonusBuys(promoRef)}
                />
                <SmartSwiper>
                  {productMediaUrls.map(el => (
                    <SmartSwiper.Slide
                      key={el.url}
                      type={el.type}
                      url={el.url}
                      alt={el.alt}
                      thumbnail={el.thumbnail}
                      // originalSrc would have the original url of the image without controlled height and width
                      originalSrc={el.originalSrc}
                    />
                  ))}
                </SmartSwiper>
              </Grid>
              <Grid item container className={classes.rightColumn} direction="column" xs={12} sm={4}>
                <ProductInfo
                  data={product}
                  fullWidth
                  items_in_room={items_in_room}
                  promotions={promotions}
                  requiredAddonsSelected={requiredAddonsSelected}
                  setUserSelectedAddons={setUserSelectedAddons}
                  userSelectedAddons={userSelectedAddons}
                  room_savings={selectedUpgrade?.room_savings}
                  viewType="list"
                />
              </Grid>
              {formattedAddon?.title && (
                <Grid item container xs={12} style={{ paddingTop: 0, paddingBottom: 0 }}>
                  <RequiredAddons
                    requiredAddons={requiredAddons}
                    setRequiredAddonsSelected={setRequiredAddonsSelected}
                    setUserSelectedAddons={setUserSelectedAddons}
                    userSelectedAddons={userSelectedAddons}
                  />
                </Grid>
              )}
            </Grid>
          </BoxWrapper>
        </PaperWrapper>
      </Grid>
      {/* => OTHER ROOM OPTIONS */}
      {selectedUpgrade && nonSelectedUpgrades && nonSelectedUpgrades.length > 0 && (
        <ProductUpgradesV2 upgrades={nonSelectedUpgrades} selectedUpgrade={selectedUpgrade} />
      )}
      {/* => PRODUCT PROMOTIONS */}
      {promotions && (
        <Grid item>
          <div ref={promoRef} className="grid-x grid-margin-y grid-margin-x">
            <ProductPromotions product={product} promotions={promotions} promoType={promotions.offer_template} />
          </div>
        </Grid>
      )}
      {/* => PRODUCT DESCRIPTION */}
      <Grid item p={2}>
        <ProductDescription product={product} items_in_room={items_in_room} />
      </Grid>
      {/* => ITEMS IN THIS ROOM */}
      {items_in_room?.length > 0 && !product.single_item_room && (
        <div className="grid-container">
          <ProductIncludesSlider items_in_room={items_in_room} product={product} heading="Items in this room" />
        </div>
      )}
    </>
  )
}

ProductDetailView.propTypes = {
  items_in_room: PropTypes.array,
  see_in_room: PropTypes.objectOf(PropTypes.array),
  product: PropTypes.shape({
    addon_items: PropTypes.array,
    alternate_images: PropTypes.array,
    brand: PropTypes.string,
    breadcrumb: PropTypes.shape({
      breadcrumb_label: PropTypes.string,
      breadcrumb_url: PropTypes.string,
    }),
    breadCrumbs: PropTypes.any,
    isAvailable: PropTypes.bool,
    on_promotion: PropTypes.object,
    primary_image_item: PropTypes.string,
    primary_image_room: PropTypes.string,
    primary_image: PropTypes.string,
    promotions: PropTypes.objectOf(PropTypes.any),
    room_configurations: PropTypes.object,
    saleFlag: PropTypes.bool,
    see_in_room: PropTypes.objectOf(PropTypes.array),
    single_item_room: PropTypes.bool,
    sku: PropTypes.string,
    title: PropTypes.string,
  }),
  room_package_upgrades: PropTypes.arrayOf(PropTypes.any),
  availabilityDate: PropTypes.instanceOf(Date),
  promotions: PropTypes.any,
}

export default ProductDetailView
