import { RoomConfigurationsModel } from '@models/api/products/room-configurations-model'
import ItemsInRoomModel from '@models/api/products/items-in-room-model'
import VariationsModel from '@models/api/products/variations-model'
import IsFeaturedModel from '@models/api/products/is-featured-model'
import ShippingCostCodeModel from '@models/api/products/shipping-cost-code-model'
import { CatalogAvailabilityModel } from '@models/api/products/catalog-availability-model'
import PackageSkusModel from '@models/api/products/package-skus-model'
import WarehouseAvailabilityModel from '@models/api/products/warehouse-availability-model'
import BreadcrumbModel from '@models/api/products/breadcrumb-model'
import CompleteYourRoomItem from '@models/api/products/complete-your-room-item'
import CloseOutModel from '@models/api/products/close-out-model'
import { PricingModel } from '@models/api/products/pricing-model'
import OnSaleModel from '@models/api/products/on-sale-model'
import PackageSkusV2Model from '@models/api/products/package-skus-v2-model'
import StrikethroughModel from '@models/api/products/strikethrough-model'
import WarrantyPricingModel from '@models/api/products/warranty-pricing-model'

class ProductModel {
  constructor(values = {}) {
    this.free_shipping = values?.free_shipping || false
    this.room_configurations = new RoomConfigurationsModel(values?.room_configurations || {})
    this.items_in_room = new ItemsInRoomModel(values?.items_in_room || {})
    this.variation_keys = (values?.variation_keys || []).map(item => item)
    this.variations = new VariationsModel(values?.variations || {})
    this.is_featured = new IsFeaturedModel(values?.is_featured || {})
    this.shipping_cost_code = new ShippingCostCodeModel(values?.shipping_cost_code || {})
    this.catalog_availability = new CatalogAvailabilityModel(values?.catalog_availability || {})
    this.package_skus = new PackageSkusModel(values?.package_skus || {})
    this.warehouseAvailability = new WarehouseAvailabilityModel(values?.warehouseAvailability || {})
    this.breadcrumb = new BreadcrumbModel(values?.breadcrumb || {})
    this.category = values?.category || ''
    this.sub_category = (values?.sub_category || []).map(item => item)
    this.complete_your_room_items = (values?.complete_your_room_items || []).map(item => new CompleteYourRoomItem(item))
    this.color = (values?.color || []).map(item => item)
    this.color_family = (values?.color_family || []).map(item => item)
    this.decor = (values?.decor || []).map(item => item)
    this.material = (values?.material || []).map(item => item)
    this.material_family = values?.material_family || ''
    this.movement = (values?.movement || []).map(item => item)
    this.piececount = values?.piececount || 0
    this.style = (values?.style || []).map(item => item)
    this.theme = (values?.theme || []).map(item => item)
    this.closeout = new CloseOutModel(values?.closeout || {})
    this.in_stores = (values?.in_stores || []).map(item => item)
    this.pricing = new PricingModel(values?.pricing || {})
    this.on_sale = new OnSaleModel(values?.on_sale || {})
    this.type = values?.type || ''
    this.title = values?.title || ''
    this.description = values?.description || ''
    this.delivery_type = values?.delivery_type || ''
    this.catalog = values?.catalog || ''
    this.room_type_code = values?.room_type_code || ''
    this.single_item_room = values?.single_item_room || false
    this.sell_individually = values?.sell_individually || false
    this.mpn = values?.mpn || ''
    this.swatch_request_flag = values?.swatch_request_flag || false
    this.monogram = values?.monogram || false
    this.customer_assembly_required = values?.customer_assembly_required || false
    this.title_slug = values?.title_slug || ''
    this.package_skus_v2 = new PackageSkusV2Model(values?.package_skus_v2 || {})
    this.primary_image = values?.primary_image || ''
    this.grid_image = values?.grid_image || ''
    this.high_res_image = values?.high_res_image || ''
    this.swatch_image = values?.swatch_image || ''
    this.alternate_images = (values?.alternate_images || []).map(item => item)
    this.primary_image_room = values?.primary_image_room || ''
    this.primary_image_item = values?.primary_image_item || ''
    this.grid_image_room = values?.grid_image_room || ''
    this.strikethrough = new StrikethroughModel(values?.strikethrough || {})
    this.warranty_pricing = new WarrantyPricingModel(values?.warranty_pricing || {})
    this.route = values?.route || ''
    this.lastModified = values?.lastModified || ''
    this.createdAt = values?.createdAt || ''
    this.sku = values?.sku || ''
  }
}

export default ProductModel
