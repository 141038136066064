import React from 'react'
import { any, arrayOf, object } from 'prop-types'
import { Box, makeStyles, Typography, useMediaQuery } from '@material-ui/core'
import { Room, theme, SystemProvider } from '@rtgdev/design-system'
import Grid from '@material-ui/core/Grid'
import ProductUpgradesSwiper from './product-upgrades-swiper'

const useStyles = makeStyles({
  roomOptions: {
    display: 'inline-block',
    [theme.breakpoints.up('md')]: {
      display: 'flex',
      flexWrap: 'nowrap',
      overflow: 'hidden',
    },
  },
})

const ProductUpgradesV2 = props => {
  const classes = useStyles()
  const { upgrades = [], selectedUpgrade = null } = props
  const isTablet = useMediaQuery(`(max-width:${theme?.breakpoints?.values?.md}px)`)
  if (selectedUpgrade?.items.length > 0) {
    selectedUpgrade.items.map(item => {
      if (item?.image) {
        return {
          ...item,
          image: `${item.image}&h=52`,
        }
      }
      return item
    })
  }
  return (
    <SystemProvider>
      <Box pb={2} flexGrow={1}>
        <Grid container className={classes.roomOptions}>
          <Grid item md style={{ maxWidth: 406 }}>
            <Box
              mt={2}
              pr={isTablet ? 0 : 2}
              style={{ borderRight: isTablet ? 'none' : '1px solid #979797', height: '100%' }}
            >
              <Typography variant="h6" display="inline">
                <strong>Selected Room</strong>
              </Typography>
              <Box pt={1}>
                <Room
                  title={selectedUpgrade?.displayNamePDP || selectedUpgrade?.title}
                  price={selectedUpgrade?.price}
                  room_savings={selectedUpgrade?.room_savings}
                  items={selectedUpgrade?.items}
                  active
                />
              </Box>
            </Box>
          </Grid>
          <Grid item md style={{ overflow: 'hidden' }}>
            <Box mt={2} pl={isTablet ? 0 : 2}>
              <Typography variant="h6" display="inline">
                <div>
                  <strong>Other Room Options</strong>&nbsp;({upgrades?.length})
                </div>
              </Typography>
              <Box pt={1}>
                <ProductUpgradesSwiper upgrades={upgrades} />
              </Box>
            </Box>
          </Grid>
        </Grid>
      </Box>
    </SystemProvider>
  )
}

ProductUpgradesV2.propTypes = {
  upgrades: arrayOf(any),
  selectedUpgrade: object,
}

export default ProductUpgradesV2
