import WarrantyPricingItemModel from '@models/api/products/warranty-pricing-item-model'

class WarrantyPricingModel {
  constructor(values = {}) {
    if (values?.FL_0) {
      this.FL_0 = new WarrantyPricingItemModel(values?.FL_0 || {})
    }
    if (values?.FL_1) {
      this.FL_1 = new WarrantyPricingItemModel(values?.FL_1 || {})
    }
    if (values?.FL_2) {
      this.FL_2 = new WarrantyPricingItemModel(values?.FL_2 || {})
    }
    if (values?.FL_3) {
      this.FL_3 = new WarrantyPricingItemModel(values?.FL_3 || {})
    }
    if (values?.FL_4) {
      this.FL_4 = new WarrantyPricingItemModel(values?.FL_4 || {})
    }
    if (values?.FL_5) {
      this.FL_5 = new WarrantyPricingItemModel(values?.FL_5 || {})
    }
    if (values?.FL_6) {
      this.FL_6 = new WarrantyPricingItemModel(values?.FL_6 || {})
    }

    if (values?.SE_0) {
      this.SE_0 = new WarrantyPricingItemModel(values?.SE_0 || {})
    }
    if (values?.SE_1) {
      this.SE_1 = new WarrantyPricingItemModel(values?.SE_1 || {})
    }
    if (values?.SE_2) {
      this.SE_2 = new WarrantyPricingItemModel(values?.SE_2 || {})
    }
    if (values?.SE_3) {
      this.SE_3 = new WarrantyPricingItemModel(values?.SE_3 || {})
    }
    if (values?.SE_4) {
      this.SE_4 = new WarrantyPricingItemModel(values?.SE_4 || {})
    }
    if (values?.SE_5) {
      this.SE_5 = new WarrantyPricingItemModel(values?.SE_5 || {})
    }
    if (values?.SE_6) {
      this.SE_6 = new WarrantyPricingItemModel(values?.SE_6 || {})
    }

    if (values?.TX_0) {
      this.TX_0 = new WarrantyPricingItemModel(values?.TX_0 || {})
    }
    if (values?.TX_1) {
      this.TX_1 = new WarrantyPricingItemModel(values?.TX_1 || {})
    }
    if (values?.TX_2) {
      this.TX_2 = new WarrantyPricingItemModel(values?.TX_2 || {})
    }
    if (values?.TX_3) {
      this.TX_3 = new WarrantyPricingItemModel(values?.TX_3 || {})
    }
    if (values?.TX_4) {
      this.TX_4 = new WarrantyPricingItemModel(values?.TX_4 || {})
    }
    if (values?.TX_5) {
      this.TX_5 = new WarrantyPricingItemModel(values?.TX_5 || {})
    }
    if (values?.TX_6) {
      this.TX_6 = new WarrantyPricingItemModel(values?.TX_6 || {})
    }

    if (values?.OOM_0) {
      this.OOM_0 = new WarrantyPricingItemModel(values?.OOM_0 || {})
    }
    if (values?.OOM_1) {
      this.OOM_1 = new WarrantyPricingItemModel(values?.OOM_1 || {})
    }
    if (values?.OOM_2) {
      this.OOM_2 = new WarrantyPricingItemModel(values?.OOM_2 || {})
    }
    if (values?.OOM_3) {
      this.OOM_3 = new WarrantyPricingItemModel(values?.OOM_3 || {})
    }
    if (values?.OOM_4) {
      this.OOM_4 = new WarrantyPricingItemModel(values?.OOM_4 || {})
    }
    if (values?.OOM_5) {
      this.OOM_5 = new WarrantyPricingItemModel(values?.OOM_5 || {})
    }
    if (values?.OOM_6) {
      this.OOM_6 = new WarrantyPricingItemModel(values?.OOM_6 || {})
    }
  }
}

export default WarrantyPricingModel
