import WarehouseAvailabilityItemModel from '@models/api/products/warehouse-availability-item-model'

class WarehouseAvailabilityModel {
  constructor(values = {}) {
    if (values?.FL) {
      this.FL = new WarehouseAvailabilityItemModel(values?.FL || {})
    }
    if (values?.SE) {
      this.SE = new WarehouseAvailabilityItemModel(values?.SE || {})
    }
    if (values?.TX) {
      this.TX = new WarehouseAvailabilityItemModel(values?.TX || {})
    }
    if (values?.OOM) {
      this.OOM = new WarehouseAvailabilityItemModel(values?.OOM || {})
    }
  }
}

export default WarehouseAvailabilityModel
