class OnSaleModel {
  constructor(values = {}) {
    this.FL_0 = values?.FL_0 || false
    this.FL_1 = values?.FL_1 || false
    this.FL_2 = values?.FL_2 || false
    this.FL_3 = values?.FL_3 || false
    this.FL_4 = values?.FL_4 || false
    this.FL_5 = values?.FL_5 || false
    this.FL_6 = values?.FL_6 || false
    this.SE_0 = values?.SE_0 || false
    this.SE_1 = values?.SE_1 || false
    this.SE_2 = values?.SE_2 || false
    this.SE_3 = values?.SE_3 || false
    this.SE_4 = values?.SE_4 || false
    this.SE_5 = values?.SE_5 || false
    this.SE_6 = values?.SE_6 || false
    this.TX_0 = values?.TX_0 || false
    this.TX_1 = values?.TX_1 || false
    this.TX_2 = values?.TX_2 || false
    this.TX_3 = values?.TX_3 || false
    this.TX_4 = values?.TX_4 || false
    this.TX_5 = values?.TX_5 || false
    this.TX_6 = values?.TX_6 || false
  }
}

export default OnSaleModel
