import WarrantyPricingItemModel from '@models/api/products/warranty-pricing-item-model'

class PackageSkuItemV2Model {
  constructor(values = {}) {
    this.sku = values?.sku || ''
    this.quantity = values?.quantity || ''
    this.price = values?.price || ''
    this.warranty_pricing = new WarrantyPricingItemModel(values?.warranty_pricing || {})
  }
}

export default PackageSkuItemV2Model
