import { PricingModel } from '@models/api/products/pricing-model'
import { CatalogAvailabilityModel } from '@models/api/products/catalog-availability-model'

class CompleteYourRoomItem {
  constructor(values = {}) {
    this.sku = values?.sku || ''
    this.title = values?.title || ''
    this.quantity = values?.quantity || 0
    this.label = values?.label || ''
    this.addon_required = values?.addon_required || false
    this.pricing = new PricingModel(values?.pricing || {})
    this.catalog_availability = new CatalogAvailabilityModel(values?.catalog_availability || {})
    this.delivery_type = values?.delivery_type || ''
    this.category = values?.category || ''
    this.sell_individually = values?.sell_individually || false
    this.image = values?.image || ''
    this.route = values?.route || ''
  }
}

export default CompleteYourRoomItem
