import PropTypes from 'prop-types'
import React from 'react'
import { useQuery } from 'react-query'
import { getLocation } from '@services/location'
import { getSeeInStore } from '@services/product'
import ProductLIA from './product-lia'

function useSeeInStore({ sku, zip, storeNumber }, options) {
  return useQuery(
    ['seeInStore', { sku, zip, storeNumber }],
    () => getSeeInStore(sku, zip).then(data => data.filter(loc => loc.storeNumber === storeNumber).length > 0),
    options,
  )
}

function useLocation({ zip }, options) {
  return useQuery(
    ['location', { zip }],
    () => getLocation(zip).then(data => ({ region: data.response.region, zone: data.response.price_zone })),
    options,
  )
}

export default function ProductLIAWrapperNew({ product, store }) {
  const { data: available, isLoading: isLocationLoading } = useLocation({ zip: store.Zip }, { enabled: !!store?.Zip })
  const { data: locData } = useSeeInStore(
    {
      sku: product.sku,
      storeNumber: store.StoreNumber,
      zip: store.Zip,
    },
    {
      enabled: !isLocationLoading,
    },
  )

  return (
    <ProductLIA
      product={product}
      store={store}
      region={locData?.region ?? 'FL'}
      zone={locData?.zone ?? '0'}
      available={available}
    />
  )
}

ProductLIAWrapperNew.propTypes = {
  product: PropTypes.shape({
    sku: PropTypes.any,
    zip: PropTypes.any,
  }),
  store: PropTypes.shape({
    StoreNumber: PropTypes.any,
    Zip: PropTypes.any,
  }),
}
